import React, { useReducer } from 'react'
import { useNavigate } from 'react-router';
import Axios from 'axios';
import storage from '../../helpers/storage';
import loader from '../../helpers/loader'
import Ip from '../../helpers/Ip';

import ResourceContext from './resourceContext';
import ResourceReducer from './resourceReducer';

import {
    GET_BANKS,
    GET_LOCATIONS,
    GET_COUNTRIES,
    GET_IP_ADDRESS,
    SET_LOADING,
    GET_TRANSACTIONS,
    GET_SETTLEMENTS,
    GET_ARCHIVED,
    GET_NETWORKS,
    GET_PAYMENTS
} from '../types';
import helperService from '../../utils/function';
import { IListQuery } from '../../utils/types';

const ResourceState = (props: any) => {

    const navigate = useNavigate();
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        banks: [],
        networks: [],
        locations: [],
        countries: [],
        country: {},
        ipData: {},
        loading: false
    }

    const [state, dispatch] = useReducer(ResourceReducer, initialState);

    const logout = async () => {

        storage.clearAuth();
        navigate('/login');
        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {}, storage.getConfig());
    }

    const getBanks = async (data: IListQuery) => {

        const { limit, page, select, order, mapped } = data;

        let mpd = mapped ? true : false
        const q = `limit=${limit ? limit.toString() : '9999'}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}&mapped=true`;

        setLoading()

        await Axios.get(`${process.env.REACT_APP_RESOURCE_URL}/banks?${q}`, storage.getConfig())
            .then((resp: any) => {
                dispatch({
                    type: GET_BANKS,
                    payload: helperService.sortData(resp.data.data, 'name')
                })

            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get banks ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get banks ${err}`)

                }

            });
    }

    const getCountries = async (limit: number = 9999) => {

        setLoading();

        await Axios.get(`${process.env.REACT_APP_RESOURCE_URL}/countries?limit=${limit}`, storage.getConfig())
            .then((resp: any) => {

                dispatch({
                    type: GET_COUNTRIES,
                    payload: resp.data.data

                });
            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get countries ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not countries ${err}`)

                }

            })

    };

    const getIpAddress = () => {

        setLoading();

        Ip.getAddress()
            .then((resp) => {


                dispatch({
                    type: GET_IP_ADDRESS,
                    payload: resp
                })

            }).catch((err) => {
                console.log(err)
            })
    }


    const setResourceList = (type: string, data: Array<any>) => {

        if (type === 'transactions') {
            dispatch({
                type: GET_TRANSACTIONS,
                payload: data
            })
        }

        if (type === 'settlements') {
            dispatch({
                type: GET_SETTLEMENTS,
                payload: data
            })
        }

        if (type === 'payments') {
            dispatch({
                type: GET_PAYMENTS,
                payload: data
            })
        }

        if (type === 'archived') {
            dispatch({
                type: GET_ARCHIVED,
                payload: data
            })
        }

    }

    const getNetworks = async () => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_RESOURCE_URL}/networks`, storage.getConfig())
        .then((resp) => {
            dispatch({
                type: GET_NETWORKS,
                payload: resp.data.data
            })
        })
        .catch((err) => {
            console.log(err)
        })

    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    const setCounries = (data: Array<any>) => {
        dispatch({
            type: GET_COUNTRIES,
            payload: data
        })
    }


    return <ResourceContext.Provider
        value={{
            payments: state.payments,
            transactions: state.transactions,
            transaction: state.transaction,
            settlements: state.settlements,
            archived: state.archived,
            banks: state.banks,
            networks: state.networks,
            locations: state.locations,
            countries: state.countries,
            country: state.country,
            ipData: state.ipData,
            loading: state.loading,
            getBanks,
            getNetworks,
            getCountries,
            setCounries,
            getIpAddress,
            setResourceList

        }}>
        {props.children}

    </ResourceContext.Provider>

}

export default ResourceState
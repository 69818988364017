import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import VaceContext from '../../../context/vace/vaceContext'
import { ISidebarWallet, IVaceContext } from '../../../utils/types'
import body from '../../../helpers/body'


const SidebarWallet = ({ toggleAccount }: ISidebarWallet) => {

    const vaceContext = useContext<IVaceContext>(VaceContext)

    const [sidebox, setSidebox] = useState<boolean>(false);

    useEffect(() => {

    }, [vaceContext.account, vaceContext.accounts])

    const toggleSideDrop = (e: any) => {
        if (e) { e.preventDefault() }
        setSidebox(!sidebox);
    }

    return (
        <>
            <div className="ui-sidebox ui-relative">

                <Link onClick={(e) => toggleSideDrop(e)} to="" className='w-100'>
                    <div className='box-details ui-line-height-small'>
                        <span className='ui-absolute fe fs-22 fe-chevron-down' style={{ right: '-0.5rem', top: '0.4rem' }}></span>
                        <h2 className='mrgb0 font-dm'>
                            <span
                                className='font-dm fs-14 text-elipsis lgr text-capitalize ui-relative'
                                style={{ color: '#AFB5DD', top: '0px' }}>
                                { vaceContext.business.name ? vaceContext.business.name : 'Corporate Business' }
                            </span>
                        </h2>
                        {
                            vaceContext.account && vaceContext.account.accountNo && !body.isObjectEmpty(vaceContext.account.bank) &&
                            <>
                                <span className='font-dm fs-13' style={{ color: '#656C89' }}>{ vaceContext.account.accountNo } - {body.captialize(vaceContext.account.bank.legalName)}</span>
                            </>
                        }

                        {
                            (!vaceContext.account || body.isObjectEmpty(vaceContext.account) || !vaceContext.account) &&
                            <>
                                <span className='font-dm fs-13' style={{ color: '#656C89' }}>0000000000 - NA</span>
                            </>
                        }
                    </div>
                </Link>

                <div className={`sidebox-drop mrgb ${sidebox ? '' : 'ui-hide'}`}>
                    <ul>
                        <li>
                            <Link to="/dashboard/wallet" className='fs-14'>
                                <span className='fs-13 font-dmmedium' style={{ color: '#cbd1f5' }}>Wallet</span>
                                <div className='ml-auto'>
                                    <span className='fs-14 font-dmbold pdr' style={{ color: '#ffe4d6' }}>{vaceContext.wallet && vaceContext.wallet.balance ? vaceContext.wallet.balance.available.toLocaleString() : 0.00}</span>
                                    <span className='fs-16 fe fe-chevron-right ui-relative' style={{ top: '2px' }}></span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link onClick={(e) => toggleAccount(e)} to="" className='fs-14'>
                                <span className='fs-13 font-dmmedium' style={{ color: '#cbd1f5' }}>Account Details</span>
                                <div className='ml-auto'>
                                    <span className='fs-16 fe fe-chevron-right ui-relative' style={{ top: '2px' }}></span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="" className='fs-14'>
                                <span className='fs-13 font-dmmedium' style={{ color: '#cbd1f5' }}>Transactions</span>
                                <div className='ml-auto'>
                                    <span className='fs-16 fe fe-chevron-right ui-relative' style={{ top: '2px' }}></span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )

}

export default SidebarWallet